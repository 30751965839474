import key from './key'
export default [
  {
    id: key.ID,
    reducer: () => import('./open-api-reducer')
  },
  {
    point: '@@components',
    namespace: key.ID,
    onload: () => [{ key: 'OpenApiView', component: () => import('./OpenApiView') }]
  }
]
