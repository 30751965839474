export const ID = '@openApi'

export default {
  ID: ID,
  GET_APP_KEY_LIST: 'GET_APP_KEY_LIST',
  ENABLE_APP_KEY: 'ENABLE_APP_KEY',
  DISABLE_APP_KEY: 'DISABLE_APP_KEY',
  DELETE_APP_KEY: 'DELETE_APP_KEY',
  CREATE_APP_KEY: 'CREATE_APP_KEY',
  GETURLLIST:`GET_URL_LIST`,
  POSTURL:`POST_URL`,
  DELETEURL:`DELETE_URL`
}
